import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { OrdersService } from '..//..//_services/orders.service';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { SnackbarComponent } from '..//..//components/snackbar/snackbar.component';
import { Observable, of } from 'rxjs';
import { map, startWith, debounceTime, switchMap, distinctUntilChanged, tap } from 'rxjs/operators';
// import {Issue} from '../../models/issue';
import { Orders } from '..//../_models/orders'


import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA } from '@angular/material';
export interface Vendors {
  name: string;
}


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent  {

 AddProductForm: FormGroup;

 isLoadingResults = false;
 ;

 configSuccess: MatSnackBarConfig = {
   panelClass: 'style-success',
   duration: 4000,
   horizontalPosition: 'left',
   verticalPosition: 'bottom'
 };

 vendors: Vendors[] = [
  { name: 'TrueValue'},
  {name: 'Corner'},
];


 constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddProductComponent>, private router: Router, private api: OrdersService, private formBuilder: FormBuilder, public snackBar: MatSnackBar) {
  this.AddProductForm = this.formBuilder.group({
    sku: ['', [Validators.required]],
    slug: ['', [Validators.required]],
    qty: [, [Validators.required]],
    vendor: ['', [Validators.required]],

  });


 }





 ngOnInit() {

   console.log(this.data)
   // this.AssignToForm = this.formBuilder.group({
   //   employee_id : '',
   //   order_id : this.data.id,

   // });






 }



 onFormSubmit(form: NgForm) {
    




   this.isLoadingResults = true;

   this.api.addProduct(this.data.id,form)
     .subscribe(res => {
        debugger
       if (res && res.message) {
         this.openSnackBar( res.message, 'success-snackbar', 'check_circle_outline');

         this.isLoadingResults = false;
         this.dialogRef.close();

       }
       else {
         debugger
         this.openSnackBar(res.error, 'error-snackbar', 'error');

         this.isLoadingResults = false;
         this.dialogRef.close();


       }

     }, (err) => {
       console.log(err);
       this.isLoadingResults = false;
     });
 }

 openSnackBar(message: string, panelClass: string, icon: string) {
   this.snackBar.openFromComponent(SnackbarComponent, {
     data: {
       message: message, icon: icon
     },

     panelClass: [panelClass],
     duration: 5000
   });
 }

}

