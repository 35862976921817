import { Component , Renderer2 } from '@angular/core';


import { Router } from '@angular/router';


import { User } from './_models';
import { UserService, AuthenticationService } from '../app/_services';

@Component({ selector: 'app', templateUrl: 'app.component.html', styleUrls: ['./app.component.css'] })
export class AppComponent {
  currentUser: User;
  title = 'operationsAdminCorner';
  sidenavWidth = 12;
   element = document.getElementsByTagName('mat-sidenav-content')

  users = [];

  constructor(

    private router: Router,
    private render: Renderer2,
    private authenticationService: AuthenticationService,
    private userService: UserService,
) {


    this.sidenavWidth = 12;

    this.getUserInfo();
  }

  getUserInfo() {
    
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x
      this.setCurrentUser();
    }
    );





  }


  setCurrentUser() {
    
    if (this.authenticationService.currentUserValue)
      this.currentUser = this.authenticationService.currentUserValue;
    else
      this.currentUser = null;
  }
  logout() {
 
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }










}