import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';





@Component({
  selector: 'app-order-img-preview',
  templateUrl: './order-img-preview.component.html',
  styleUrls: ['./order-img-preview.component.css']
})
export class OrderImgPreviewComponent  {


  isLoadingResults = false;
  ;








  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<OrderImgPreviewComponent>) {


  }

  opts = [];




  ngOnInit() {






  }







}