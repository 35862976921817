import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employees-details',
  templateUrl: './employees-details.component.html',
  styleUrls: ['./employees-details.component.css']
})
export class EmployeesDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
