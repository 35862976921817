import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';

import { AuthGuard } from './_helpers';

import {OrdersDetailsComponent} from './components/orders/orders-details/orders-details.component';
import {OrdersListComponent} from './components/orders/orders-list/orders-list.component';
import {EmployeesDetailsComponent} from './components/employees/employees-details/employees-details.component';
import {EmployeesListComponent} from './components/employees/employees-list/employees-list.component';
import {EmployeesAddComponent} from './components/employees/employees-add/employees-add.component';
import {ProductDetailsComponent} from './components/products/product-details/product-details.component';
import {DriversListComponent} from './components/drivers/drivers-list/drivers-list.component';
import {DriversAddComponent} from './components/drivers/drivers-add/drivers-add.component';
import {AllDriverOrdersComponent} from './components/orders/all-driver-orders/all-driver-orders.component';
import {DeletedOrdersComponent} from './components/orders/deleted-orders/deleted-orders.component';


import { from } from 'rxjs';


const routes: Routes = [
  { path: '', component: OrdersListComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'orders-list', component: OrdersListComponent },
  { path: 'orders-details/:id', component: OrdersDetailsComponent },
  { path: 'employees-list', component: EmployeesListComponent },
  { path: 'employees-details/:id', component: EmployeesDetailsComponent },
  { path: 'employees-add', component: EmployeesAddComponent },
  { path: 'product-details/:id', component: ProductDetailsComponent },
  { path: 'drivers-list', component: DriversListComponent },
  { path: 'drivers-add', component: DriversAddComponent },
  { path: 'all-driver-orders', component: AllDriverOrdersComponent },
  { path: 'deleted-orders', component: DeletedOrdersComponent },
  
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);