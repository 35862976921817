import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { Drivers } from '..//../../_models/drivers';
import { Router } from '@angular/router';
import { DriversService } from '..//..//..//_services/drivers.service';
import { EmployeeCountService } from '..//..//..//_services/employeeCount.service';
import { MatPaginator, MatTableDataSource, MatDialog, PageEvent, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

import { DriversDialogsEditComponent } from '..//..//..//dialogs/drivers-dialogs/drivers-dialogs-edit/drivers-dialogs-edit.component';
import { DriversDialogsDeleteComponent } from '..//..//..//dialogs/drivers-dialogs/drivers-dialogs-delete/drivers-dialogs-delete.component';
import { async } from '@angular/core/testing';
@Component({
  selector: 'app-drivers-list',
  templateUrl: './drivers-list.component.html',
  styleUrls: ['./drivers-list.component.css']
})
export class DriversListComponent implements OnInit {


  displayedColumns: string[] = ['username' , 'actions'];
  // data: Translation[] = [];
  Databset: DriversService | null;
  data: MatTableDataSource<any>  = new MatTableDataSource([]) ;
  index: number;

  pageIndex: number = 0;
  limit: number = 10;
  count: number = 0;

  isLoadingResults = true;

  constructor(private api: DriversService  , public dialog: MatDialog,   private cdr: ChangeDetectorRef , private router: Router, public form: FormBuilder , public snackBar: MatSnackBar) { 



  }

  public filterForm = this.form.group({

    invoice : [''],
  


  });




  ngOnInit() {
    this.getDrivers();

  }



public getDriversOrder(filterForm)
{
  this.api.getDriversOrder(filterForm)
  .subscribe(res => {
    if (res['data']) {
      
      this.cdr.detectChanges();
      this.data.data  = null;
      var arrToView = [];
      arrToView.push(res['data']);
      this.data.data =arrToView;
      console.log(      this.data.data)
      // this.count = res['count'];



    }
    else {
      this.data.data  = null;
      // this.count = 0;

    }
    this.isLoadingResults = false;
  }, err => {
    console.log(err);
    this.isLoadingResults = false;
  });

}

  public getDrivers() {



    this.api.getDrivers()
      .subscribe(res => {
        if (res['data']) {
          

          this.data.data = res['data'];
          // this.count = res['count'];
          // this.cdr.detectChanges();
          console.log(      this.data.data)

        }
        else {
          this.data.data  = null;
          // this.count = 0;

        }
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });

  }


  startEdit(i: number, id: number, username: string) {
    
    const dialogRef = this.dialog.open(DriversDialogsEditComponent, {
      data: { id: id, username: username}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.getDrivers();
        
      }
    });
  }

  deleteItem(i: number, id: number, username: string) {
    this.index = i;
    const dialogRef =  this.dialog.open(DriversDialogsDeleteComponent, {
      data: { id: id, username: username }
    });
     dialogRef.afterClosed().subscribe(result => {
       
      if (result === 1) {
        // this.getDrivers();
        this.data.data =   this.data.data.filter(u => u.id !== id);

        
      }
    });

  }

//   public changePage(event){
    
//     console.log('event',event)
//  
//           this.limit = event.pageSize;

//           if (event.previousPageIndex >=  event.pageIndex )
//           {
//             if (event.pageIndex == 0)
//             this.pageIndex = 1 //last page handel 
//             else
//             this.pageIndex = event.previousPageIndex;
//           }
//           else
//           {       
//                  this.pageIndex = event.pageIndex + 1;
//           }

          
          
       
          

        
//           // this.skip = event.pageSize * event.pageIndex;
//           this.getCountEmployeesOrders(this.pageIndex )

    
    
//   }









}

