import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

import { Router } from '@angular/router';
import { DriversService } from '..//..//..//_services/drivers.service';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA } from '@angular/material';
import { PasswordValidation } from '../../../_helpers/password-validator';

@Component({
  selector: 'app-drivers-add',
  templateUrl: './drivers-add.component.html',
  styleUrls: ['./drivers-add.component.css']
})
export class DriversAddComponent implements OnInit {

 employeeForm: FormGroup;
  isLoadingResults = false;

  id: number = 0;


  index: number;








  constructor(private router: Router, private api: DriversService, private formBuilder: FormBuilder, public snackBar: MatSnackBar) {

  }


  ngOnInit() {
    this.employeeForm = this.formBuilder.group({
      username: ['', [Validators.required]],


     
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  get f() { return this.employeeForm.controls; }


  onFormSubmit(form: NgForm) {

    this.addDrivers();
  }



  // Function to remove uploaded file


  addDrivers() {
    // seconds.pipe(timeout(900))

    this.isLoadingResults = true;
    this.api.addDrivers(this.employeeForm.value)
      .subscribe(res => {
        
        if (res)
        {
          this.isLoadingResults = false;
          this.openSnackBar('Divers successfully created!', 'success-snackbar', 'check_circle_outline');
  
          this.isLoadingResults = false;
        }
        else
        {
          this.openSnackBar('error', 'error-snackbar', 'error');

          this.isLoadingResults = false;
        }
     

       
      
      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  openSnackBar(message: string, panelClass: string, icon: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message, icon: icon
      },

      panelClass: [panelClass],
      duration: 5000
    });
  }

}









