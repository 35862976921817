import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { Orders } from '..//../../_models/orders';
import { Router, ActivatedRoute  , Params} from '@angular/router';
import { OrdersService } from '..//..//..//_services/orders.service';
import { MatPaginator, MatTableDataSource, MatDialog, PageEvent, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { AddDialogComponent } from '..//..//..//dialogs/add/add.dialog.component';
import { AddStickersDialogComponent } from '..//..//..//dialogs/add-stickers/add-stickers.dialog.component';
// import { triggerAsyncId } from 'async_hooks';
import { SharedService } from '..//..//../_services/shared.service';
@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css']
})
export class OrdersListComponent implements OnInit {


  displayedColumns: string[] = ['invoice', 'total_price' , 'area',  'payment_type' , 'employee'   , 'showProducts', 'stickers' ,   'ordered_at' , 'order_status' , 'print' ];
  // data: Translation[] = [];
  Databset: OrdersService | null;

  data: MatTableDataSource<Orders>  = new MatTableDataSource([]) ;
  index: number;
orderCount  =  {


};
lastfilterForm;
orderStatusColors  = {
  ongoing : '#ffa500',
  pending : '#3420da',
  assigned : '#00547c',
  onhold : '#afaf0b',
  failed : '#ff6347',
  fulfilled : '#0e7c01'



}
  pageIndex: number = 0;
  limit: number = 10;
  count: number = 0;
  currentPage : number = 0;
  isLoadingResults = true;
  tomorrow = new Date(); 

  constructor(private route: ActivatedRoute, private api: OrdersService, public dialog: MatDialog,   private cdr: ChangeDetectorRef , private router: Router, public form: FormBuilder , public snackBar: MatSnackBar ,   private sharedService: SharedService) { 



  }
  @ViewChild('paginator' , {static: true}) paginator: MatPaginator;

  // public filterForm: FormGroup;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  
  public filterForm = this.form.group({
    date: [''],
    statusType: [''],
    invoice : [''],
  


  });



  ngOnInit() {
    
// let page  :number = 1;
this.lastfilterForm = this.sharedService.filterForm;

      this.filterForm = this.form.group({
        date: this.lastfilterForm.date ? new Date (this.lastfilterForm.date) : '',
        statusType:this.lastfilterForm.statusType ?  this.lastfilterForm.statusType : '',
        invoice : this.lastfilterForm.invoice ? this.lastfilterForm.invoice : '',
      
    
    
      });
      console.log(this.filterForm);
      debugger
      if (this.lastfilterForm.page)
      {
      // page = Number(this.lastfilterForm.page);
      // this.pageIndex = page;
      // this.currentPage = page;
      
    
      }
      
      this.getOrderss(this.filterForm.value  , 1 );
      this.getCountOrders();



  }


  print(invoice){

    window.open('https://api.cornerkw.com/api/print-order/' + invoice, '_blank');


  }



  public getCountOrders() {

    this.api.getCountOrdersByStatus()
      .subscribe(res => {
        if (res) {
           

          this.orderCount = res;


        }
        else {
          this.data = null;


        }
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });

  }


  public getOrderss(filterForm , page) {
debugger
    filterForm.page = page;

    console.log(this.filterForm.value);
    this.api.getOrderss(this.filterForm.value)
      .subscribe(res => {
        if (res) {
           

          this.data.data = res['data'];

          this.count = res['count'];
          // this.pageIndex =res['meta'].current_page
          // this.limit = res['meta'].per_page;
          this.cdr.detectChanges();

        }
        else {
          this.data.data  = null;
          this.count = 0;

        }
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });

  }
  public changePage(event){
    debugger
    console.log('event',event)
    this.currentPage = event.pageIndex;
          this.limit = event.pageSize;
debugger
          if (event.previousPageIndex >=  event.pageIndex )
          {
            if (event.pageIndex == 0)
            this.pageIndex = 1 //last page handel 
            else
            this.pageIndex = event.previousPageIndex;
          }
          else
          {       
                 this.pageIndex = event.pageIndex + 1;
          }

          
          
       
          

        
          // this.skip = event.pageSize * event.pageIndex;
          this.getOrderss(this.filterForm.value, this.pageIndex )

    
    
  }




  addNew(id) {
    
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: { id: id }
    });
  }
  addStickers(id) {
    
    const dialogRef = this.dialog.open(AddStickersDialogComponent, {
      data: { id: id }
    });
  }
    navigateToProductDetailsSaveFiltter(invoice) {
    debugger
    this.sharedService.filterForm = this.filterForm.value;
    this.router.navigate(['/product-details/', invoice]);
  }

  navigateToOrdersDetailsSaveFiltter(invoice) {
    debugger
    this.sharedService.filterForm = this.filterForm.value;
    this.router.navigate(['/orders-details/', invoice]);
  }
  
  applyFilter(filterValue: string) {
    this.data.filter = filterValue.trim().toLowerCase();

    if (this.data.paginator) {
      this.data.paginator.firstPage();
    }
  }




  openSnackBar(message: string, panelClass: string, icon: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message, icon: icon
      },

      panelClass: [panelClass],
      duration: 5000
    });
  }



}
