import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { Orders } from '..//../../_models/orders';
import { Router } from '@angular/router';
import { OrdersService } from '..//..//..//_services/orders.service';
import { EmployeeCountService } from '..//..//..//_services/employeeCount.service';
import { MatPaginator, MatTableDataSource, MatDialog, PageEvent, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { AddDialogComponent } from '..//..//..//dialogs/add/add.dialog.component';

@Component({
  selector: 'app-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.css']
})
export class EmployeesListComponent implements OnInit {


  displayedColumns: string[] = ['username', 'ongoing' , 'fulfilled' , 'assigned' ,  'onhold' ,  'failed' , 'orders' ];
  // data: Translation[] = [];
  Databset: OrdersService | null;
  data: MatTableDataSource<any>  = new MatTableDataSource([]) ;
  index: number;
orderCount  =  {


};
  pageIndex: number = 0;
  limit: number = 10;
  count: number = 0;

  isLoadingResults = true;
  tomorrow = new Date(); 

  constructor(private api: OrdersService, public EmployeeCountApi : EmployeeCountService  , public dialog: MatDialog,   private cdr: ChangeDetectorRef , private router: Router, public form: FormBuilder , public snackBar: MatSnackBar) { 



  }


  // public filterForm: FormGroup;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;





  ngOnInit() {
    this.getCountEmployeesOrders(1);

  }





  public getCountEmployeesOrders(page) {



    this.EmployeeCountApi.getCountEmployeesOrders(page)
      .subscribe(res => {
        if (res['data']) {
           

          this.data.data = res['data'];
          this.count = res['count'];
          this.cdr.detectChanges();


        }
        else {
          this.data.data  = null;
          this.count = 0;

        }
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });

  }


  public changePage(event){
    
    console.log('event',event)
 
          this.limit = event.pageSize;

          if (event.previousPageIndex >=  event.pageIndex )
          {
            if (event.pageIndex == 0)
            this.pageIndex = 1 //last page handel 
            else
            this.pageIndex = event.previousPageIndex;
          }
          else
          {       
                 this.pageIndex = event.pageIndex + 1;
          }

          
          
       
          

        
          // this.skip = event.pageSize * event.pageIndex;
          this.getCountEmployeesOrders(this.pageIndex )

    
    
  }









}
