import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Employees } from '../_models/employees';
import { environment } from '../../environments/environment';




@Injectable({
  providedIn: 'root'
})
export class EmployeeCountService {

  dataChange: BehaviorSubject<Employees[]> = new BehaviorSubject<Employees[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private http: HttpClient) { }
  get data(): Employees[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }




  createEmployee(employee): Observable<Employees> {
    // var arr = [];
    // arr.push(employee);
    return this.http.post<Employees>(`${environment.apiUrl}/supervisors/createEmployee/`, employee).pipe(
      tap((employee: Employees) => console.log(`added Employees w/ id=${employee.userName}`)),
      catchError(this.handleError<Employees>('createEmployee'))
    );
  }

  updateEmployees(employees): Observable<any> {
    // var arr = [];
    // arr.push(employee);
    const url = `${environment.apiUrl}/supervisors/assign_employee`;
    return this.http.post<Employees>(`${environment.apiUrl}/supervisors/assign_employee`, employees).pipe(

      tap(_ => console.log(`updated Employees`)),
      catchError(this.handleError<any>('updateEmployees'))
    );
  }


  getEmployees(): Observable<any[]> {

    return this.http.get<any[]>(`${environment.apiUrl}/supervisors/get_employees`)
      .pipe(

        tap(employees =>
          console.log('Fetch employees')
        ),
        catchError(this.handleError('get employees', []))

      );
  }



  getCountEmployeesOrders(page): Observable<any[]> {
    let params = new HttpParams()
      .set('page', page ? page : '')
    return this.http.get<any[]>(`${environment.apiUrl}/supervisors/count_employees_order?`  ,  { params: params })
      .pipe(

        tap(employees =>
          console.log('Fetch Count Employees By Status')
        ),
        catchError(this.handleError('get Count Employees By Status', []))

      );
  }

  deleteEmployees(id): Observable<Employees> {

    const url = `${environment.apiUrl}/api/Employees/${id}`;

    return this.http.delete<Employees>(url).pipe(
      tap(_ => console.log(`deleted Employees key=${id}`)),
      catchError(this.handleError<Employees>('deleteEmployees'))
    );
  }

  updateStatusEmployees(employee): Observable<any> {

    const url = `${environment.apiUrl}/api/employee/status/`;
    return this.http.put<Employees>(url, employee).pipe(

      tap(_ => console.log(`updated Employees`)),
      catchError(this.handleError<any>('updateEmployees'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}