import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Drivers } from '../_models/drivers';
import { environment } from '../../environments/environment';




@Injectable({
  providedIn: 'root'
})
export class DriversService {

  dataChange: BehaviorSubject<Drivers[]> = new BehaviorSubject<Drivers[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private http: HttpClient) { }
  get data(): Drivers[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  getDrivers(): Observable<any[]> {



    return this.http.get<any[]>(`${environment.apiUrl}/supervisors/drivers`)
      .pipe(

        tap(categorys =>
          console.log('Fetch Drivers')
        ),
        catchError(this.handleError('get Driverss', []))

      );
  }


  getDriversOrder(obj): Observable<Drivers[]> {

    let params = new HttpParams()

    if (obj.invoice) {
      params =  params.append('invoice', obj.invoice)
    }

    return this.http.get<Drivers[]>(`${environment.apiUrl}/supervisors/searchDriver/` + obj.invoice) 
      .pipe(

        tap(categorys =>
          console.log('Fetch Drivers Order')
        ),
        catchError(this.handleError('get Drivers Order', []))

      );
  }

  getDriver(id: number): Observable<Drivers> {
    const url = `${environment.apiUrl}/supervisors/order_details/${id}`;
    return this.http.get<Drivers>(`${environment.apiUrl}/supervisors/order_details/${id}`).pipe(
      tap(_ => console.log(`fetched Drivers id=${id}`)),
      catchError(this.handleError<Drivers>(`getDrivers id=${id}`))
    );
  }

  getProduct(id: number): Observable<any> {

    return this.http.get<Drivers>(`${environment.apiUrl}/supervisors/getProducts/${id}`).pipe(
      tap(_ => console.log(`fetched Products id=${id}`)),
      catchError(this.handleError<Drivers>(`Products id=${id}`))
    );
  }

  addDrivers(drivers): Observable<Drivers> {
    
    // var arr = [];
    // arr.push(drivers);
    return this.http.post<Drivers>(`${environment.apiUrl}/supervisors/drivers/`, drivers).pipe(
      tap((drivers: Drivers) => console.log(`added Drivers w/ id=${drivers.id}`)),
      catchError(this.handleError<Drivers>('addDrivers'))
    );
  }

  updateDrivers(drivers): Observable<any> {
    
    // var arr = [];
    // arr.push(drivers);
    var objToUpdate  : any = {};
    if (drivers.username)
    objToUpdate.username = drivers.username;
    if (drivers.password)
    objToUpdate.password = drivers.password;
    if (drivers.confirmPassword)
    objToUpdate.confirmPassword = drivers.confirmPassword;

    return this.http.put<Drivers>(`${environment.apiUrl}/supervisors/drivers/${drivers.id}`, objToUpdate).pipe(

      tap(_ => console.log(`updated Drivers`)),
      catchError(this.handleError<any>('updateDrivers'))
    );
  }


  getEmployees(): Observable<any[]> {

    return this.http.get<any[]>(`${environment.apiUrl}/supervisors/get_employees`)
      .pipe(

        tap(employees =>
          console.log('Fetch employees')
        ),
        catchError(this.handleError('get employees', []))

      );
  }





  deleteDrivers(id): Observable<Drivers> {

    const url = `${environment.apiUrl}/supervisors/drivers/${id}`;

    return this.http.delete<Drivers>(url).pipe(
      tap(_ => console.log(`deleted Drivers key=${id}`)),
      catchError(this.handleError<Drivers>('deleteDrivers'))
    );
  }

  updateStatusDrivers(drivers): Observable<any> {

    const url = `${environment.apiUrl}/api/drivers/status/`;
    return this.http.put<Drivers>(url, drivers).pipe(

      tap(_ => console.log(`updated Drivers`)),
      catchError(this.handleError<any>('updateOrders'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}