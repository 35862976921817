import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-raed',
  templateUrl: './test-raed.component.html',
  styleUrls: ['./test-raed.component.css']
})
export class TestRaedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
