import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { Orders } from '..//../../_models/orders';
import { Router } from '@angular/router';
import { OrdersService } from '..//..//..//_services/orders.service';
import { DriversService } from '..//..//..//_services/drivers.service';
import { MatPaginator, MatTableDataSource, MatDialog, PageEvent, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { OrderImgPreviewComponent } from '..//..//..//dialogs/order-img-preview/order-img-preview.component';
import { Observable, of } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, tap, map } from 'rxjs/operators';
export interface Drivers {
  id: number;
  username: string;
}


@Component({
  selector: 'app-all-driver-orders',
  templateUrl: './all-driver-orders.component.html',
  styleUrls: ['./all-driver-orders.component.css']
})
export class AllDriverOrdersComponent implements OnInit {

  displayedColumns: string[] = [ 'order_id','customer_name',  'order_status' ,  'order_status_at' , 'total_price' , 'order_proof_image',  'take_image_at', 'other_note'  , 'ordered_at'];
  // data: Translation[] = [];
  Databset: OrdersService | null;

  data: MatTableDataSource<Orders>  = new MatTableDataSource([]) ;
  index: number;
orderCount  =  {


};
DriversForm = new FormControl();
drivers: Drivers[] = [

];
filteredOptions: Observable<Drivers[]>;

  pageIndex: number = 0;
  limit: number = 10;
  count: number = 0;

  isLoadingResults = true;
  tomorrow = new Date(); 

  constructor(private api: OrdersService,   private driversApi : DriversService  , public dialog: MatDialog,   private cdr: ChangeDetectorRef , private router: Router, public form: FormBuilder , public snackBar: MatSnackBar) { 

    this.filteredOptions = this.DriversForm.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
            return this.filter(val || '')
       }) 
    )

  }
  @ViewChild('paginator' , {static: true}) paginator: MatPaginator;

  // public filterForm: FormGroup;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  public filterForm = this.form.group({
    order_id: [''],
    driver_id: [''],
    date: [''],
  


  });
  opts = [];

  getData() {
    return this.opts.length ?
      of(this.opts) :
      this.driversApi.getDrivers().pipe(tap(data => this.opts = data))
  }

  getdata()
  {
     
    this.driversApi.getDrivers()
    .subscribe(res => {
       
      if (res) { 
        this.drivers = (res["data"]);


        // console.log(this.data);
      }
      else {
        this.drivers = null;


      }
      this.isLoadingResults = false;
    }, err => {
      console.log(err);
      this.isLoadingResults = false;
    });
  }


  ngOnInit() {
    this.getAllDriverOrders(this.filterForm  ,1 );

  }





  public getAllDriverOrders(filterForm , page) {

    filterForm.page = page;
    if (this.DriversForm.value && this.DriversForm.value.id)
    {
      filterForm.driver_id  = this.DriversForm.value.id;
    }
    console.log(this.filterForm.value);
    this.api.getAllDriverOrders(filterForm)
      .subscribe(res => {
        if (res) {
           

          this.data.data = res['data'];

          this.count = res['meta'].total;
          this.cdr.detectChanges();

        }
        else {
          this.data.data  = null;
          this.count = 0;

        }
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });

  }
  public changePage(event){
    
    console.log('event',event)
 
          this.limit = event.pageSize;

          if (event.previousPageIndex >=  event.pageIndex )
          {
            if (event.pageIndex == 0)
            this.pageIndex = 1 //last page handel 
            else
            this.pageIndex = event.previousPageIndex;
          }
          else
          {       
                 this.pageIndex = event.pageIndex + 1;
          }

          
          
       
          

        
          // this.skip = event.pageSize * event.pageIndex;
          this.getAllDriverOrders(this.filterForm.value, this.pageIndex )

    
    
  }


  filter(val: string): Observable<any[]> {
     
if (val['username'])
{
  val = val['username']
}
    // call the service which makes the http-request
    return this.getData()
     .pipe(
       map(response => response["data"].filter(option => { 
         return option.username.toLowerCase().indexOf(val.toLowerCase()) === 0
         
       }))
     )

     
   }  




  displayFn(item: any): string {
    if (item == undefined) { return }
    return item.username
  }


  showImg(img) {
    
    const dialogRef = this.dialog.open(OrderImgPreviewComponent, {
      data: { id: img }
    });
  }
  applyFilter(filterValue: string) {
    this.data.filter = filterValue.trim().toLowerCase();

    if (this.data.paginator) {
      this.data.paginator.firstPage();
    }
  }




  openSnackBar(message: string, panelClass: string, icon: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message, icon: icon
      },

      panelClass: [panelClass],
      duration: 5000
    });
  }



}
