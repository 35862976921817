import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';


// import { fakeBackendProvider } from './_helpers';

import { appRoutingModule } from './app.routing';
import { AppGlobals } from './app.global';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AddDialogComponent } from './dialogs/add/add.dialog.component';
import { SharedService } from './_services/shared.service';
import { AddStickersDialogComponent } from './dialogs/add-stickers/add-stickers.dialog.component';
import { AlertComponent } from './_components';
import { TestRaedComponent } from './test-raed/test-raed.component';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAnimatedIconComponent } from './components/mat-animated-icon/mat-animated-icon.component';

import {MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule, MatAutocompleteModule} from '@angular/material';

import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MAT_SNACK_BAR_DATA } from '@angular/material';
import {
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatTabsModule,
    MatTreeModule,
    MatTooltipModule,
    MatSliderModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatChipsModule,
    MatBadgeModule,
    MatSlideToggleModule,
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VarDirective } from './directives/var.directive';;
import { OrdersListComponent } from './components/orders/orders-list/orders-list.component';;
import { OrdersDetailsComponent } from './components/orders/orders-details/orders-details.component'
;
import { EmployeesListComponent } from './components/employees/employees-list/employees-list.component';;
import { EmployeesDetailsComponent } from './components/employees/employees-details/employees-details.component'
;
import { EmployeesAddComponent } from './components/employees/employees-add/employees-add.component'
;
import { ProductDetailsComponent } from './components/products/product-details/product-details.component'
;
import { DriversListComponent } from './components/drivers/drivers-list/drivers-list.component';
import { DriversAddComponent } from './components/drivers/drivers-add/drivers-add.component';;
import { DriversDialogsEditComponent } from './dialogs/drivers-dialogs/drivers-dialogs-edit/drivers-dialogs-edit.component'
;
import { DriversDialogsDeleteComponent } from './dialogs/drivers-dialogs/drivers-dialogs-delete/drivers-dialogs-delete.component'
;
import { AllDriverOrdersComponent } from './components/orders/all-driver-orders/all-driver-orders.component';
import { DeletedOrdersComponent } from './components/orders/deleted-orders/deleted-orders.component';
import { OrderImgPreviewComponent } from './dialogs/order-img-preview/order-img-preview.component';
import { AddProductComponent } from './dialogs/add-product/add-product.component';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        FormsModule,
        HttpClientModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatGridListModule,
        MatTabsModule,
        MatTreeModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatSliderModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatChipsModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatAutocompleteModule,

    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        AlertComponent,
        TestRaedComponent,
        SnackbarComponent,
        MatAnimatedIconComponent,
         VarDirective,
         OrdersListComponent,
         OrdersDetailsComponent,
         AddDialogComponent
,
         EmployeesListComponent,
         EmployeesDetailsComponent
,
         EmployeesAddComponent ,
         ProductDetailsComponent,
         DriversListComponent,
         DriversAddComponent
,
         DriversDialogsEditComponent ,
         DriversDialogsDeleteComponent ,
         AllDriverOrdersComponent ,
         DeletedOrdersComponent ,
         OrderImgPreviewComponent,
         AddProductComponent,
         AddStickersDialogComponent
        ],




    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        [{ provide: MAT_SNACK_BAR_DATA, useValue: {} }],
        [AppGlobals],
        SharedService



    ],
    entryComponents: [AddDialogComponent  ,  SnackbarComponent , DriversDialogsEditComponent , DriversDialogsDeleteComponent , OrderImgPreviewComponent  ,AddProductComponent , AddStickersDialogComponent],

    bootstrap: [AppComponent]
})
export class AppModule { };