import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { OrdersService } from '..//..//_services/orders.service';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { SnackbarComponent } from '..//..//components/snackbar/snackbar.component';
import { Observable, of } from 'rxjs';
import { map, startWith, debounceTime, switchMap, distinctUntilChanged, tap } from 'rxjs/operators';
// import {Issue} from '../../models/issue';
import { Orders } from '..//../_models/orders'
export interface Language {
  value: number;
  viewValue: string;
}

import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA } from '@angular/material';
export interface Employees {
  id: number;
  username: string;
}




@Component({
  selector: 'app-add.dialog',
  templateUrl: '../../dialogs/add/add.dialog.html',
  styleUrls: ['../../dialogs/add/add.dialog.css']
})

export class AddDialogComponent {

  // AssignToForm: FormGroup;

  isLoadingResults = false;
  ;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 4000,
    horizontalPosition: 'left',
    verticalPosition: 'bottom'
  };


  AssignToForm = new FormControl();
  employees: Employees[] = [

  ];
  filteredOptions: Observable<Employees[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddDialogComponent>, private router: Router, private api: OrdersService, private formBuilder: FormBuilder, public snackBar: MatSnackBar) {

    this.filteredOptions = this.AssignToForm.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this.filter(val || '')
      })
    )

  }

  opts = [];

  getData() {
    return this.opts.length ?
      of(this.opts) :
      this.api.getEmployees().pipe(tap(data => this.opts = data))
  }

  getdata() {
    this.api.getEmployees()
      .subscribe(res => {
        if (res) {
          this.employees = (res);


          // console.log(this.data);
        }
        else {
          this.employees = null;


        }
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  ngOnInit() {

    console.log(this.data)
    // this.AssignToForm = this.formBuilder.group({
    //   employee_id : '',
    //   order_id : this.data.id,

    // });






  }

  filter(val: string): Observable<any[]> {
    // call the service which makes the http-request
    if (val['username']) {
      val = val['username']
    }
    return this.getData()
      .pipe(
        map(response => response.filter(option => {
          return option.username.toLowerCase().indexOf(val.toLowerCase()) === 0

        }))
      )


  }




  displayFn(item: any): string {
    if (item == undefined) { return }
    return item.username
  }

  onFormSubmit(form: NgForm) {
     

    console.log(this.AssignToForm.value);

    var obj = {
      employee_id: form['id'],
      order_id: this.data.id,
    };

    this.isLoadingResults = true;

    this.api.updateOrders(obj)
      .subscribe(res => {
         
        if (res) {
          this.openSnackBar('Assign success ', 'success-snackbar', 'check_circle_outline');

          this.isLoadingResults = false;
          this.dialogRef.close();

          this.router.navigate(['/orders-details', this.data.id]);
        }
        else {
          this.openSnackBar('error ', 'error-snackbar', 'error');

          this.isLoadingResults = false;
          this.dialogRef.close();


        }

      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  openSnackBar(message: string, panelClass: string, icon: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message, icon: icon
      },

      panelClass: [panelClass],
      duration: 5000
    });
  }

}

