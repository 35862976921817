import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {

  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data$: Observable<any> = this.data.asObservable();
  private date;
  private statusType;
  private invoice;
  private page;

  constructor() { }

  setData(newData) {
    this.data.next(newData);
  }

  set filterForm(obj) {
    this.date = obj.date;
    this.statusType = obj.statusType;
    this.invoice = obj.invoice;
    this.page = obj.page;

  }

  get filterForm() {

    let obj = {date : '' , statusType : '' ,invoice : '' ,  page : '' }
    obj.date = this.date;
    obj.statusType = this.statusType;
    obj.invoice = this.invoice;
    obj.page = this.page;

    return obj;
  }

}