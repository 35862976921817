import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { User } from '..//_models/user';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class UserService {
   
  dataChange: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private http: HttpClient) { }
  get data(): User[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  getUsers (obj): Observable<User[]> {
    
    const params = new HttpParams()
    .set('email', obj.email  ? obj.email : '')
    .set('fullName', obj.fullName  ? obj.fullName : '')
    .set('isActive', obj.isActive  ? obj.isActive : '')
    .set('pageNumber', obj.pageIndex  ? obj.pageIndex : '')
    .set('limit', obj.limit  ? obj.limit : '')





    
    
    return this.http.get<User[]>(`${environment.apiUrl}/api/user/admin/search?`   ,   { params: params })
      .pipe(
        
        tap(users => 
            console.log('Fetch User')
            ),
        catchError(this.handleError('get Users', []))
   
        );
  }

  getGroupsPermissions (obj): Observable<User[]> {
    
    const params = new HttpParams()







    
    
    return this.http.get<User[]>(`${environment.apiUrl}/api/user/groupsPermissions/search?`   ,   { params: params })
      .pipe(
        
        tap(users => 
            console.log('Fetch User')
            ),
        catchError(this.handleError('get Users', []))
   
        );
  }


  getGroups (obj): Observable<User[]> {
    
    const params = new HttpParams()







    
    
    return this.http.get<User[]>(`${environment.apiUrl}/api/group/search?`   ,   { params: params })
      .pipe(
        
        tap(users => 
            console.log('Fetch User')
            ),
        catchError(this.handleError('get Users', []))
   
        );
  }


  getUser(id: number): Observable<User> {
    const url = `${environment.apiUrl}/api/user/admin/${id}`;
    return this.http.get<User>(url).pipe(
      tap(_ => console.log(`fetched User id=${id}`)),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }

  addUser (user): Observable<User> {
    // var arr = [];
    // arr.push(user);
    return this.http.post<User>(`${environment.apiUrl}/api/user/admin/` , user).pipe(
      tap((user: User) => console.log(`added User w/ id=${user.id}`)),
      catchError(this.handleError<User>('addUser'))
    );
  }

  updateUser (user): Observable<any> {
    // var arr = [];
    // arr.push(user);
    const url = `${environment.apiUrl}/api/user/admin/`;
      return this.http.put<User>(url  ,  user ).pipe(

      tap(_ => console.log(`updated User`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  getProfile(): Observable<User> {
    const url = `${environment.apiUrl}/api/myProfile/`;
    return this.http.get<User>(url).pipe(
      tap(_ => console.log(`fetchedProfile `)),
      catchError(this.handleError<User>(`getProfile`))
    );
  }





  updateProfile (user): Observable<any> {
    // var arr = [];
    // arr.push(user);
    const url = `${environment.apiUrl}/api/myProfile/`;
      return this.http.put<User>(url  ,  user ).pipe(

      tap(_ => console.log(`updated profile`)),
      catchError(this.handleError<any>('update profile'))
    );
  }


  deleteUser (id): Observable<User> {
    
    const url = `${environment.apiUrl}/api/User/admin/${id}`;

    return this.http.delete<User>(url).pipe(
      tap(_ => console.log(`deleted User key=${id}`)),
      catchError(this.handleError<User>('deleteUser'))
    );
  }



  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}