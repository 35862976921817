import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';

import { User } from '../_models';



import { environment } from '../../environments/environment';





@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        
        try 
        {
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
            this.currentUser = this.currentUserSubject.asObservable();
        }
        catch(e)
        {
            this.currentUserSubject = null;
            this.currentUser  = null;
            this.logout();
        }

    }

    public get currentUserValue(): User {
        
        return this.currentUserSubject.value;
    }

    login(username, password) {
        
        let headers = new HttpHeaders({
            'Accept' : 'application/json',
            'username': username,
            'password':password });
        let options = { headers: headers };
        return this.http.post<any>(`${environment.apiUrl}/supervisors/login`,  null , options)
            .pipe(map(user => {
                 
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                // environment.auth.token = user.data.token;
                // environment.auth.refreshToken = user.data.refreshToken;
                return user;
                
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        // environment.auth.token = null;
        // environment.auth.refreshToken = null;
        this.currentUserSubject.next(null);
    }
}