import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import { DriversService } from '..//..//..//_services/drivers.service';
import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA } from '@angular/material';
import { PasswordValidation } from '../../../_helpers/password-validator';

import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-drivers-dialogs-edit',
  templateUrl: './drivers-dialogs-edit.component.html',
  styleUrls: ['./drivers-dialogs-edit.component.css']
})
export class DriversDialogsEditComponent {
  hide = true;
  employeeForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<DriversDialogsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  private formBuilder: FormBuilder,  public DriversService: DriversService  ,  public snackBar: MatSnackBar) { 
      this.employeeForm = this.formBuilder.group({
        id : [this.data.id ,  [Validators.required]],
        username: ['', [Validators.required]],
  
  
       
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
      }, {
        validator: PasswordValidation.MatchPassword
      });
    }

formControl = new FormControl('', [
Validators.required
// Validators.email,
]);





get f() { return this.employeeForm.controls; }

onFormSubmit() {
// emppty stuff

this.DriversService.updateDrivers(this.employeeForm.value)
}

onNoClick(): void {
this.dialogRef.close();
}

stopEdit() {



this.DriversService.updateDrivers(this.employeeForm.value)
.subscribe(res => {
// let id = res['_id'];
console.log("updateDriver")
// this.isLoadingResults = false;
// this.router.navigate(['/product-details', id]);
this.openSnackBar('Update Driver Successfully! ', 'success-snackbar', 'check_circle_outline');

}, (err) => {
console.log(err);
this.openSnackBar('error ', 'error-snackbar', 'error');

}
);
}

openSnackBar(message: string, panelClass: string, icon: string) {
  this.snackBar.openFromComponent(SnackbarComponent, {
    data: {
      message: message, icon: icon
    },

    panelClass: [panelClass],
    duration: 5000
  });
}
}
