import { Component, OnInit, Inject, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { OrdersService } from '..//..//..//_services/orders.service';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router, ActivatedRoute  , Params} from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA, MatDialog } from '@angular/material';
import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';

import { AddProductComponent } from '..//..//..//dialogs/add-product/add-product.component';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  ordersForm: FormGroup;

  name: string = '';

  products = [];
  defaultpProducts = []
  id: number = 0;
  status: false;
  searchAppearance: false;
  index: number;
  isLoadingResults = false;

  file: File;
  quantityToUpdate = [];

  orderlistFilterForm = {}; 
  constructor(private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private api: OrdersService, private formBuilder: FormBuilder, public snackBar: MatSnackBar, private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    debugger
  //   this.route.params.subscribe((params: Params) => {
  //     this.orderlistFilterForm = {date: params['date'] , statusType  : params['statusType'] ,  invoice  :  params['invoice']   , page :  params['page'] };
  //     console.log(this.orderlistFilterForm);
  // });
          //  this.orderlistFilterForm = params['searchData'];




    this.ordersForm = this.formBuilder.group({
      order_id: '',
      employee_id: '',
      order_note: { value: '', disabled: true },
      order_status: '',
      order: {},

    });

    this.getProduct(this.route.snapshot.params['id']);


  }
  @ViewChild('fileInput', { static: true }) el: ElementRef;
  imageUrl: any = '';
  editFile: boolean = true;
  removeUpload: boolean = false;




  getProduct(id) {

    this.api.getProduct(id).subscribe(data => {


      if (data) {
        this.products = data;
        this.defaultpProducts = this.products;
      }
      else {
        this.products = [];
        this.defaultpProducts = [];
      }



    });

  }
  onFormSubmit() {
     
    var orderToUodate = [];

 this.products.forEach(element => {
  
 
  if (element.oldQnt ||element.oldQnt == 0 )
  {
    if (element.oldQnt != element.quantity)
    {

      orderToUodate.push({'sku' : element.sku , 'qty' : element.quantity})
    }
  }


  AddProductComponent
 });

 console.log(orderToUodate)
    this.updateOrder(orderToUodate);
  }

  updateOrder(orderToUodate) {
    // seconds.pipe(timeout(900))

    this.isLoadingResults = true;
    this.api.updateOrderQty(this.route.snapshot.params['id'], orderToUodate)
      .subscribe(res => {
         
        if (res) {
          this.isLoadingResults = false;
          this.openSnackBar('Order successfully Update!', 'success-snackbar', 'check_circle_outline');

          this.isLoadingResults = false;
        }
        else {
          this.openSnackBar('error', 'error-snackbar', 'error');

          this.isLoadingResults = false;
        }




      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }


  handleMinus(value) {
     
    console.log(value);
    if (value.quantity != 0) {
      value.oldQnt = value.quantity;
      value.quantity--;
      

    }
    else {
      value.oldQnt = value.quantity;
      value.quantity = 0
 

    }

  }
  handlePlus(value) {
    value.oldQnt = value.quantity;
    value.quantity++;


  }
  addProduct() {
    
    const dialogRef = this.dialog.open(AddProductComponent, {
      data: { id: this.route.snapshot.params['id'] }
    });
  }

  openSnackBar(message: string, panelClass: string, icon: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message, icon: icon
      },

      panelClass: [panelClass],
      duration: 5000
    });
  }




}




