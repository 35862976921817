import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Orders } from '../_models/orders';
import { environment } from '../../environments/environment';
import { JsonPipe } from '@angular/common';




@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  dataChange: BehaviorSubject<Orders[]> = new BehaviorSubject<Orders[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private http: HttpClient ) { }
  get data(): Orders[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  getOrderss(obj): Observable<Orders[]> {

    

    if (obj.date) {
      // obj.date.setDate(obj.date.getDate() + 1)
      // var todayDate = new Date(obj.date).toISOString().slice(0,10);
      var d = new Date(obj.date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();


      if (month.length < 2)
        month = '0' + month;

      if (day.length < 2)
        day = '0' + day;

      var date = [year, month, day].join('-');

    }


    let params = new HttpParams()
      .set('page', obj.page ? obj.page : '')

    if (date) {
      params = params.set('date', date)
    }
    if (obj.statusType) {
      params = params.set('status', obj.statusType)
    }
    if (obj.invoice) {
      params = params.append('invoice', obj.invoice)
    }





    return this.http.get<Orders[]>(`${environment.apiUrl}/supervisors/get_orders?`, { params: params })
      .pipe(

        tap(categorys =>
          console.log('Fetch Orders')
        ),
        catchError(this.handleError('get Orderss', []))

      );
  }

  getAllDriverOrders(obj): Observable<Orders[]> {

    
    if (obj.date) {
      // obj.date.setDate(obj.date.getDate() + 1)
      // var todayDate = new Date(obj.date).toISOString().slice(0,10);
      var d = new Date(obj.date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();


      if (month.length < 2)
        month = '0' + month;

      if (day.length < 2)
        day = '0' + day;

      var date = [year, month, day].join('-');

    }


    let params = new HttpParams()
      .set('page', obj.page ? obj.page : '')
      if (date) {
        params = params.set('ordered_at', date)
      }
      if (obj.order_id) {
        params = params.append('order_id', obj.order_id)
      }
  
      if (obj.driver_id) {
        params = params.append('driver_id', obj.driver_id)
      }
    return this.http.get<Orders[]>(`${environment.apiUrl}/supervisors/allDriverOrders?`, { params: params })
      .pipe(

        tap(categorys =>
          console.log('Fetch All Driver Orders')
        ),
        catchError(this.handleError('get All Driver Orders', []))

      );
  }


  getDeletedOrders(obj): Observable<Orders[]> {

    if (obj.date) {
      // obj.date.setDate(obj.date.getDate() + 1)
      // var todayDate = new Date(obj.date).toISOString().slice(0,10);
      var d = new Date(obj.date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();


      if (month.length < 2)
        month = '0' + month;

      if (day.length < 2)
        day = '0' + day;

      var date = [year, month, day].join('-');

    }
    let params = new HttpParams()
      .set('page', obj.page ? obj.page : '')
      if (date) {
        params = params.set('ordered_at', date)
      }
      if (obj.order_id) {
        params = params.append('order_id', obj.order_id)
      }
  
      if (obj.driver_id) {
        params = params.append('driver_id', obj.driver_id)
      }
  

    return this.http.get<Orders[]>(`${environment.apiUrl}/supervisors/deletedOrders?`, { params: params })
      .pipe(

        tap(categorys =>
          console.log('Fetch All Driver Orders')
        ),
        catchError(this.handleError('get All Driver Orders', []))

      );
  }



  getOrders(id: number): Observable<Orders> {
    const url = `${environment.apiUrl}/supervisors/order_details/${id}`;
    return this.http.get<Orders>(`${environment.apiUrl}/supervisors/order_details/${id}`).pipe(
      tap(_ => console.log(`fetched Orders id=${id}`)),
      catchError(this.handleError<Orders>(`getOrders id=${id}`))
    );
  }

  getProduct(id: number): Observable<any> {

    return this.http.get<Orders>(`${environment.apiUrl}/supervisors/getProducts/${id}`).pipe(
      tap(_ => console.log(`fetched Products id=${id}`)),
      catchError(this.handleError<Orders>(`Products id=${id}`))
    );
  }

  // updateOrderQty(id: number , arrayUpdate): Observable<any> {
  //    
  //   var myJSON =JSON.stringify({ products: arrayUpdate });
  //   console.log(JSON.stringify({ products: arrayUpdate }));



  //   return this.http.get<Orders>(`${environment.apiUrl}/supervisors/updateOrder/${id}` ,myJSON ).pipe(
  //     tap(_ => console.log(`fetched Products id=${id}`)),
  //     catchError(this.handleError<Orders>(`Products id=${id}`))
  //   );
  // }


  updateOrderQty(id: number , arrayUpdate): Observable<any> {
    // var myJSON =JSON.stringify({ products: arrayUpdate });
    // console.log(JSON.stringify({ products: arrayUpdate }));
    const url = `${environment.apiUrl}/supervisors/updateOrder/${id}`;
    return this.http.put<Orders>(url, { products: arrayUpdate }).pipe(

      tap(_ => console.log(`updated Orders`)),
      catchError(this.handleError<any>('updateOrders'))
    );
  }

  getSticker(id: number): Observable<any> {

    return this.http.get<Orders>(`${environment.apiUrl}/supervisors/getSticker/${id}`).pipe(
      tap(_ => console.log(`fetched Sticker id=${id}`)),
      catchError(this.handleError<Orders>(`Sticker id=${id}`))
    );
  }

  addStickers(id , stickers): Observable<any> {

    return this.http.post<Orders>(`${environment.apiUrl}/supervisors/addStickers/${id}`, stickers).pipe(

      tap(_ => console.log(`added stickers`)),
      catchError(this.handleError<any>('addedstickers'))
    );
  }

  addOrders(category): Observable<Orders> {
    // var arr = [];
    // arr.push(category);
    return this.http.post<Orders>(`${environment.apiUrl}/api/category/`, category).pipe(
      tap((category: Orders) => console.log(`added Orders w/ id=${category.invoice}`)),
      catchError(this.handleError<Orders>('addOrders'))
    );
  }

  updateOrders(orders): Observable<any> {
    // var arr = [];
    // arr.push(category);
    const url = `${environment.apiUrl}/supervisors/assign_order`;
    return this.http.post<Orders>(`${environment.apiUrl}/supervisors/assign_order`, orders).pipe(

      tap(_ => console.log(`updated Orders`)),
      catchError(this.handleError<any>('updateOrders'))
    );
  }
  
  addProduct(id , product): Observable<any> {

    return this.http.post<Orders>(`${environment.apiUrl}/supervisors/addProduct/${id}`, product).pipe(

      tap(_ => console.log(`added product`)),
      catchError(this.handleError<any>('addedproduct'))
    );
  }
  getEmployees(): Observable<any[]> {

    return this.http.get<any[]>(`${environment.apiUrl}/supervisors/get_employees`)
      .pipe(

        tap(employees =>
          console.log('Fetch employees')
        ),
        catchError(this.handleError('get employees', []))

      );
  }

  getCountOrdersByStatus(): Observable<any[]> {

    return this.http.get<any[]>(`${environment.apiUrl}/supervisors/count_orders_by_status`)
      .pipe(

        tap(employees =>
          console.log('Fetch Count Orders By Status')
        ),
        catchError(this.handleError('get Count Orders By Status', []))

      );
  }



  deleteOrders(id): Observable<Orders> {

    const url = `${environment.apiUrl}/api/Orders/${id}`;

    return this.http.delete<Orders>(url).pipe(
      tap(_ => console.log(`deleted Orders key=${id}`)),
      catchError(this.handleError<Orders>('deleteOrders'))
    );
  }

  updateStatusOrders(category): Observable<any> {

    const url = `${environment.apiUrl}/api/category/status/`;
    return this.http.put<Orders>(url, category).pipe(

      tap(_ => console.log(`updated Orders`)),
      catchError(this.handleError<any>('updateOrders'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}