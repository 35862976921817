import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { OrdersService } from '../../_services/orders.service';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { Observable, of } from 'rxjs';
import { map, startWith, debounceTime, switchMap, distinctUntilChanged, tap } from 'rxjs/operators';
// import {Issue} from '../../models/issue';
import { Orders } from '../../_models/orders'

import { Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA } from '@angular/material';




@Component({
  selector: 'add-stickers-add.dialog',
  templateUrl: '../../dialogs/add-stickers/add-stickers.dialog.html',
  styleUrls: ['../../dialogs/add-stickers/add-stickers.dialog.css']
})

export class AddStickersDialogComponent {

  // AssignToForm: FormGroup;

  isLoadingResults = false;
  ;

  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 4000,
    horizontalPosition: 'left',
    verticalPosition: 'bottom'
  };


  // StickersForm = new FormControl();

  StickersForm: FormGroup;
  loaded: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddStickersDialogComponent>, private router: Router, private api: OrdersService, private formBuilder: FormBuilder, public snackBar: MatSnackBar) {

    this.StickersForm = this.formBuilder.group({
      order_id : [this.data.id ,  [Validators.required]],
      number_of_bags: ['', [Validators.required]]
  
    });

  }

  


  getdata(id) {
    
    this.api.getSticker(id)
      .subscribe(res => {
        if (res) {

        this.StickersForm.patchValue({
          order_id : res.data["order_id"],
          number_of_bags : res.data["number_of_bags"]
 
         });
         this.loaded = true

        }
        else {
    
// this.StickersForm = null;

        }
        this.isLoadingResults = false;
      }, err => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  ngOnInit() {
  //  this.getdata(this.data.id)
  this.api.getSticker(this.data.id)
  .subscribe(res => {
    if (res) {


    // this.StickersForm = res.data;


    this.StickersForm.controls['number_of_bags'].setValue(res.data["number_of_bags"]);
    console.log(this.StickersForm.value);
      // console.log(this.data);
      this.loaded = true;

    }
    else {

// this.StickersForm = null;

    }
    this.isLoadingResults = false;
  }, err => {
    console.log(err);
    this.isLoadingResults = false;
  });









  }







  onFormSubmit(form: NgForm) {
     

    // console.log(this.StickersForm.value);


    this.isLoadingResults = true;

    this.api.addStickers(this.data.id , form)
      .subscribe(res => {
         
        if (res) {
          this.openSnackBar('Add Stickers success ', 'success-snackbar', 'check_circle_outline');

          this.isLoadingResults = false;
          this.dialogRef.close();

          // this.router.navigate(['/orders-details', this.data.id]);
          window.open('https://api-operations.cornerkw.com/api/v1/supervisors/showSticker/' + this.data.id, '_blank');

        }
        else {
          this.openSnackBar('error ', 'error-snackbar', 'error');

          this.isLoadingResults = false;
          this.dialogRef.close();


        }

      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });
  }

  print(invoice){

    window.open('https://api-operations.cornerkw.com/api/v1/supervisors/showSticker/' + invoice, '_blank');


  }
  openSnackBar(message: string, panelClass: string, icon: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message, icon: icon
      },

      panelClass: [panelClass],
      duration: 5000
    });
  }

}

