import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import { DriversService } from '..//..//..///_services/drivers.service';
import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-drivers-dialogs-delete',
  templateUrl: './drivers-dialogs-delete.component.html',
  styleUrls: ['./drivers-dialogs-delete.component.css']
})
export class DriversDialogsDeleteComponent {

  constructor(public dialogRef: MatDialogRef<DriversDialogsDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public DriversService: DriversService , public snackBar: MatSnackBar , private router: Router) { }

onNoClick(): void {
this.dialogRef.close();
}

// confirmDelete() {
//    
//   this.TranslationService.deleteTranslation(this.data.key);
// }
confirmDelete() {
this.DriversService.deleteDrivers(this.data.id)
.subscribe(res => {


if (res) {
  
this.openSnackBar('Delete Drivers Successfully!', 'success-snackbar' , 'check_circle_outline');

}
else {


this.openSnackBar(res["data"], 'error-snackbar', 'error');

}


}, (err) => {
console.log(err);

});
}

openSnackBar(message: string, panelClass: string, icon: string) {
this.snackBar.openFromComponent(SnackbarComponent, {
data: {
message: message, icon: icon
},

panelClass: [panelClass],
duration: 5000
});
}


}





