import { Component, OnInit, Inject, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { OrdersService } from '..//..//..//_services/orders.service';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA, MatDialog } from '@angular/material';
import { from, interval } from 'rxjs';


import { SnackbarComponent } from '..//..//..//components/snackbar/snackbar.component';
export interface Employees {
  id: any,
  username: any

}


@Component({
  selector: 'app-orders-details',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.css']
})
export class OrdersDetailsComponent implements OnInit {

  ordersForm: FormGroup;

  name: string = '';

  products = [];
  id: number = 0;
  status: false;
  searchAppearance: false;
  index: number;
  isLoadingResults = false;

  file: File;
 
  employees: Employees[] = [

  ];
  constructor(private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private api:OrdersService, private formBuilder: FormBuilder, public snackBar: MatSnackBar, private cd: ChangeDetectorRef) {

  }

  ngOnInit() {

 


    this.ordersForm = this.formBuilder.group({
      order_id : '',
      employee_id  :'',
      order_note : {value : ''  , disabled: true},
      order_status : '',
      order : {},

    });

    this.getProduct(this.route.snapshot.params['id']);

    this.api.getEmployees()
    .subscribe(res => {
      
      if (res) {
        this.employees = (res);


        // console.log(this.data);
      }
      else {
        this.employees = null;


      }
      this.isLoadingResults = false;
    }, err => {
      console.log(err);
      this.isLoadingResults = false;
    });


  }
  @ViewChild('fileInput', { static: true }) el: ElementRef;
  imageUrl: any = '';
  editFile: boolean = true;
  removeUpload: boolean = false;




  getProduct(id) {

    this.api.getOrders(id).subscribe(data => {
      
      this.id = data["data"].id;
      data = data["data"];

        this.ordersForm.patchValue({
         order_id : this.route.snapshot.params['id'],
          order_status  :data.order_status,
          order_note : data.order_note,
          order : data.order['products'],
          employee_id : data.emp_id

        });
         
        console.log( this.ordersForm);
this.products = this.ordersForm.value.order;


      //  this.categoryForm.setControl('translations', this.setTranslations(this.translations));


      // this.imageUrl = this.ordersForm.value.imageUrl;

    });

  }





  onFormSubmit(form: NgForm) {


      this.updateOrders();

  }

  updateOrders() {

    this.isLoadingResults = true;
    console.log(this.ordersForm.value);

    let objToUpdate = {
      order_id  :'',
      employee_id : ''
    }
    objToUpdate.order_id =this.ordersForm.value.order_id;
    objToUpdate.employee_id =this.ordersForm.value.employee_id
    this.api.updateOrders(objToUpdate)
      .subscribe(res => {


        if (res) {
          
          this.openSnackBar('success', 'success-snackbar', 'check_circle_outline');
          this.isLoadingResults = false;
          this.router.navigate(['orders-details/', objToUpdate.order_id]);

        }
        else {
          this.isLoadingResults = false;
          this.openSnackBar('error', 'error-snackbar', 'error');
        }

      }, (err) => {
        console.log(err);
        this.isLoadingResults = false;
      });

  }



  openSnackBar(message: string, panelClass: string, icon: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message, icon: icon
      },

      panelClass: [panelClass],
      duration: 5000
    });
  }




}




